.loading {
	background-color: transparent;
	animation: move 5s infinite;
}

.gradientBorder {
	border: 4px solid transparent;
	background-image: linear-gradient(rgba(1, 1, 1, 0), rgba(1, 1, 1, 0)),
		radial-gradient(1304.32% 76.09% at 87.24% 100%, #a055fa 0%, #5f15e8 100%);
	border-radius: 12px;
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15);
}

@keyframes move {
	0% {
		background-color: rgba(255, 255, 255, 0.05);
	}

	40% {
		background-color: rgba(255, 255, 255, 0.1);
	}

	70% {
		background-color: rgba(255, 255, 255, 0.4);
	}

	100% {
		background-color: rgba(255, 255, 255, 0.05);
	}
}

.pulse {
	@apply bg-green-800 bg-opacity-10;
	border: 4px solid transparent;
		background-image: linear-gradient(rgba(1, 1, 1, 0), rgba(1, 1, 1, 0)),
			radial-gradient(1304.32% 76.09% at 87.24% 100%, #55faa7 0%, #15e84a9f 100%);
		border-radius: 12px;
		background-origin: border-box;
		background-clip: content-box, border-box;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
		box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 0 0px rgba(134 239 172, 0.5);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0px rgba(134, 239, 172, 0.5);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(134, 239, 172, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0px rgba(134, 239, 172, 0);
	}
}
